import React from 'react';
import { MessageSquare, Plus, ChevronLeft, ChevronRight } from 'lucide-react';
import { Loader2 } from 'lucide-react';

const Sidebar = ({
    isSidebarOpen,
    setIsSidebarOpen,
    chats,
    loading,
    activeChat,
    handleChatSelect,
    handleNewChat,
    handleOpenRenameModal,
    handleDeleteChat,
}) => (
    <div className={`h-full flex flex-col`}>
        {/* Sidebar Header */}
        <div className="flex items-center justify-between p-4 border-b">
            <div className="flex items-center space-x-2">
                <MessageSquare size={24} className="text-white" />
                {isSidebarOpen && <h2 className="text-lg font-bold text-white">Chats</h2>}
            </div>
            <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="text-white hover:text-gray-400"
            >
                {isSidebarOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
            </button>
        </div>

        {/* New Chat Button */}
        <div className="p-4">
            <button
                onClick={handleNewChat}
                className={`flex items-center justify-center bg-[#16c2d9] hover:bg-[#0da6ba] text-white rounded-lg transition 
    ${isSidebarOpen ? 'w-full py-2 px-4' : 'w-10 h-10 mx-auto'}`}
            >
                <Plus size={18} />
                {isSidebarOpen && <span className="ml-2">New Chat</span>}
            </button>

        </div>

        {/* Chat List */}
        <div className="flex-1 overflow-y-auto">
            {loading ? (
                <div className="flex justify-center items-center p-4">
                    <Loader2 className="animate-spin" size={24} />
                </div>
            ) : (
                chats.map((chat) => (
                    <div
                        key={chat._id}
                        className={`p-4 border-b hover:bg-gray-800 transition cursor-pointer relative flex ${isSidebarOpen ? 'items-center' : 'justify-center'
                            } ${activeChat?._id === chat._id ? 'bg-[blue-50]' : ''}`}
                        onClick={() => handleChatSelect(chat)}
                    >
                        {isSidebarOpen ? (
                            <>
                                <MessageSquare size={20} className="text-white flex-shrink-0" />
                                <div className="ml-3 flex-1">
                                    <h3 className="font-medium text-white truncate">{chat.chatName}</h3>
                                    <p className="text-xs text-gray-400">{new Date(chat.createdAt).toLocaleDateString()}</p>
                                </div>
                                <div className="flex space-x-2">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleOpenRenameModal(chat);
                                        }}
                                        className="text-gray-500 hover:text-blue-600"
                                    >
                                        <i className="fas fa-edit"></i>
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteChat(chat._id);
                                        }}
                                        className="text-gray-500 hover:text-red-600"
                                    >
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="relative group">
                                <MessageSquare
                                    size={24}
                                    className={`${activeChat?._id === chat._id ? 'text-black-600' : 'text-gray-600'
                                        }`}
                                />
                                <div
                                    className="absolute left-full ml-2 px-3 py-2 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-50"
                                    style={{ top: '50%', transform: 'translateY(-50%)' }}
                                >
                                    {chat.chatName}
                                </div>
                            </div>
                        )}
                    </div>
                ))
            )}
        </div>
    </div>
);

export default Sidebar;
