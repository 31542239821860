import React, { useRef } from 'react';
import {
  ChevronRight,
  Brain,
  Layers,
  ListOrdered,
  GraduationCap
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Navbar, Footer } from '../components/shared';
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton.tsx'; // Import the button

const HowItWorksPage = () => {
  const navigate = useNavigate();
  const emailInputRef = useRef(null);

  const steps = [
    {
      icon: <Layers className="w-12 h-12 text-blue-600" />,
      title: "Step 1: Personalized Profile",
      description: "Create your profile and let our AI understand your strengths and areas of improvement."
    },
    {
      icon: <ListOrdered className="w-12 h-12 text-green-600" />,
      title: "Step 2: Adaptive Learning",
      description: "Receive tailored MCQs and practice sets designed to optimize your learning."
    },
    {
      icon: <GraduationCap className="w-12 h-12 text-purple-600" />,
      title: "Step 3: Progress Tracking",
      description: "Monitor your performance with real-time analytics and recommendations."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white">
      <Navbar />

      {/* Header Section */}
      <header className="container mx-auto px-8 py-12 md:py-16 grid grid-cols-1 md:grid-cols-2 gap-8 items-center bg-black">
        <div>
          <h2 className="text-3xl md:text-5xl font-bold text-white mb-4 md:mb-6 leading-snug">
            Discover How MDCAT.ai Works
          </h2>
          <p className="text-base md:text-xl text-gray-400 mb-6 md:mb-8">
            Understand our step-by-step approach to help you excel in your MDCAT preparation.
          </p>
          <button
            onClick={() => navigate('/')}
            className="bg-[#16c2d9] text-white text-sm md:text-base px-4 md:px-6 py-3 rounded-lg hover:bg-[#0da6ba] transition flex items-center"
          >
            Back to Home <ChevronRight className="ml-2" />
          </button>
        </div>
        <div className="relative flex justify-center md:block ml-20 md:ml-32">
          <div className="w-full max-w-md">
            <ReactPlayer
              url="https://www.youtube.com/shorts/YIo3ZsC1f-Q"
              width="100%"
              height="280px"
              controls
              className="rounded-xl shadow-2xl transform hover:scale-105 transition duration-300"
            />
          </div>
        </div>
      </header>

      {/* Steps Section */}
      <section id="steps" className="py-12 bg-black">
        <div className="container mx-auto px-8">
          <div className="text-center mb-8 md:mb-12">
            <h3 className="text-2xl md:text-4xl font-bold text-white mb-4">
              Simplified in 3 Easy Steps
            </h3>
            <p className="text-sm md:text-xl text-gray-400 max-w-xl mx-auto">
              Get started quickly and make the most of your preparation journey with our AI-powered platform.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
            {steps.map((step, index) => (
              <div
                key={index}
                className="bg-white/90 dark:bg-gray-900/90 p-6 rounded-xl shadow-lg hover:shadow-2xl transition transform hover:-translate-y-2"
              >
                {step.icon}
                <h4 className="text-lg md:text-xl font-bold mt-4 mb-2 text-white">
                  {step.title}
                </h4>
                <p className="text-sm md:text-base text-gray-400">
                  {step.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <FloatingWhatsAppButton /> {/* Add the floating button */}

      <Footer />
    </div>
  );
};

export default HowItWorksPage;
