import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';  // Assuming you're using react-router
import { Navbar, Footer } from '../components/shared';
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton.tsx'; // Import the button

import {
    ChevronRight,
    CheckCircle,
    Book,
    Brain,
    Target,
    Rocket,
    MessageCircleQuestion,
    BarChart3,
    CogIcon,
    Menu,
    X,
    MessageSquare
} from 'lucide-react';

const MDCATLandingPage = () => {
    const [email, setEmail] = useState('');
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [highlightForm, setHighlightForm] = useState(false);
    const emailInputRef = useRef(null); // Create a ref for the email input

    const features = [
        {
            icon: <CogIcon className="w-10 h-10 md:w-12 md:h-12 text-blue-600" />,
            title: "Adaptive AI Learning",
            description: "Personalized MCQs that adapt to your skill level"
        },
        {
            icon: <BarChart3 className="w-10 h-10 md:w-12 md:h-12 text-green-600" />,
            title: "Performance Analytics",
            description: "Detailed insights into your preparation progress"
        },
        {
            icon: <MessageCircleQuestion className="w-10 h-10 md:w-12 md:h-12 text-purple-600" />,
            title: "AI Doubt Resolver",
            description: "24/7 instant doubt clearing chatbot"
        },
        {
            icon: <Book className="w-10 h-10 md:w-12 md:h-12 text-yellow-600" />,
            title: "Comprehensive Syllabus Coverage",
            description: "Complete preparation across all topics"
        },
        {
            icon: <Rocket className="w-10 h-10 md:w-12 md:h-12 text-red-600" />,
            title: "Detailed Analytics & Monitoring",
            description: "Track your progress and refine your strategy"
        },
        {
            icon: <Target className="w-10 h-10 md:w-12 md:h-12 text-teal-600" />,
            title: "Tailored Learning Experience",
            description: "Customized plans to maximize your success"
        }
    ];

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setSubmissionStatus('');

        try {
            const response = await fetch('https://mdcat-backend.vercel.app/api/waitlist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                setSubmissionStatus('success');
                setEmail('');
                alert(data.message || 'Successfully joined waitlist!');
            } else {
                setSubmissionStatus('error');
                alert(data.message || 'Failed to join waitlist');
            }
        } catch (error) {
            setSubmissionStatus('error');
            console.error('Error submitting email:', error);
            alert('Network error. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const highlightEmailInput = () => {
        // Highlight the email input box only
        if (emailInputRef.current) {
            emailInputRef.current.classList.add('ring-4', 'ring-blue-1000', 'ring-opacity-50'); // Darker blue

            // Remove the highlight after 2 seconds
            setTimeout(() => {
                if (emailInputRef.current) {
                    emailInputRef.current.classList.remove('ring-4', 'ring-blue-900', 'ring-opacity-50');
                }
            }, 3000);
        }

        // Optional alert for feedback
        alert('Please enter your email to join the waitlist!');
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleLogout = () => {
        const confirmLogout = window.confirm("Are you sure you want to log out?");
        if (confirmLogout) {
            localStorage.removeItem('user');
            window.location.reload(); // Reload the page to reflect changes after logout
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white">
            {/* Navigation */}
           
            <Navbar  />

            {/* Hero Section */}
            <header className="containe px-8 py-12 md:py-16 grid md:grid-cols-2 gap-12 items-center bg-black">
                <div className="space-y-6">
                    <h2 className="text-4xl font-bold text-white">
                        Get Ready for MDCAT with AI-powered Learning
                    </h2>
                    <p className="text-lg text-gray-400">
                        Join the waitlist for a personalized and adaptive study experience with real-time
                        analytics, performance tracking, and more!
                    </p>

                    <form
                        onSubmit={handleEmailSubmit}
                        className={`flex items-center  space-x-4 mt-8 ${highlightForm ? 'ring-4 ring-blue-1000 ring-opacity-50' : ''}`}
                    >
                        <input
                            type="email"
                            ref={emailInputRef}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full md:w-80 px-4 py-2 rounded-full bg-black border border-gray-300 text-white"
                            placeholder="Enter your email"
                            required
                        />
                        <button
                            type="submit"
                            className={`bg-[#16c2d9] text-white px-6 py-2 rounded-full hover:bg-[#0da6ba] transition ${isLoading ? 'cursor-wait' : ''}`}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Submitting...' : 'Join Waitlist'}
                        </button>
                    </form>
                    {submissionStatus && (
                        <p className={`text-center mt-4 ${submissionStatus === 'success' ? 'text-green-600' : 'text-red-600'}`}>
                            {submissionStatus === 'success' ? 'Successfully added to the waitlist!' : 'Failed to join waitlist. Please try again.'}
                        </p>
                    )}
                </div>

                <div className="relative flex justify-center md:block ml-20 md:ml-32"> {/* Updated margin-left for better alignment */}
    <div className="w-full max-w-md">
        <ReactPlayer
            url="https://www.youtube.com/shorts/YIo3ZsC1f-Q"
            width="100%"
            height="280px"
            controls
            className="rounded-xl shadow-2xl transform hover:scale-105 transition duration-300"
        />
    </div>
</div>


            </header>

            {/* Features Section */}
            <section id="features" className="py-12 bg-black">
                <div className="container mx-auto px-8 ">
                    <h2 className="text-3xl font-bold text-center text-white mb-12">Key Features</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
                        {features.map((feature, index) => (
                            <div
                                key={index}
                                className="p-6 rounded-xl shadow-lg hover:shadow-2xl transition transform hover:-translate-y-2 bg-white/90 dark:bg-gray-900/90 "
                            >
                                <div className="mb-4">
                                    {feature.icon}
                                </div>
                                <h3 className="text-xl font-semibold text-white mb-2 ">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-400">
                                    {feature.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Footer */}
            {/* <footer className="bg-blue-900 text-white py-8 md:py-12">
                <div className="container mx-auto px-4 text-center">
                    <h4 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4">Join the MDCAT Revolution</h4>
                    <p className="text-base md:text-xl mb-6 md:mb-8">
                        Be the first to transform your medical entrance exam preparation
                    </p>
                    <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
                        <button
                            className="bg-white text-blue-900 px-6 py-3 rounded-full hover:bg-blue-100 transition relative group w-full md:w-auto"
                            onClick={() => alert('Coming soon! Join our waitlist.')}
                        >
                            Play Store
                            <div className="absolute bottom-0 left-0 w-full p-2 bg-black text-white text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Coming Soon! Join our waitlist, we'll notify you.
                            </div>
                        </button>
                        <button
                            className="bg-white text-blue-900 px-6 py-3 rounded-full hover:bg-blue-100 transition relative group w-full md:w-auto"
                            onClick={() => alert('Coming soon! Join our waitlist.')}
                        >
                            App Store
                            <div className="absolute bottom-0 left-0 w-full p-2 bg-black text-white text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Coming Soon! Join our waitlist, we'll notify you.
                            </div>
                        </button>
                    </div>
                </div>
            </footer> */}

<FloatingWhatsAppButton /> {/* Add the floating button */}

            <Footer />

        </div>
    );
};

export default MDCATLandingPage;
