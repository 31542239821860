import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MDCATLandingPage from './screens/home.tsx';
import HowItWorksPage from './screens/HowItWorksPage.tsx';
import ChatbotInterface from "./components/chatbot/ChatbotInterface.tsx";
import ProtectedRoute from './components/ProtectedRoute';
import AuthPage from "./components/AuthPage.jsx";
import { Helmet } from 'react-helmet';
import FloatingWhatsAppButton from './components/FloatingWhatsAppButton.tsx'; // Import the button
import PrivacyPolicyPage from "./screens/PrivacyPolicyPage.tsx";
import TermsOfServicePage  from "./screens/TermsOfServicePage.tsx";
function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet>
                <title>Home - MDCAT.ai</title>
                <meta name="description" content="Welcome to MDCAT AI-powered preparation platform." />
                <meta name="keywords" content="MDCAT, AI, exam preparation, study, chatbot, AI tutor" />
                <meta charSet="utf-8" />
              </Helmet>
              <MDCATLandingPage />
            </>
          }
        />
        <Route
          path="/how-it-works"
          element={
            <>
              <Helmet>
                <title>How It Works - MDCAT.ai</title>
                <meta name="description" content="Learn how MDCAT AI-powered platform helps with preparation." />
                <meta name="keywords" content="How it works, MDCAT, AI, study guide" />
              </Helmet>
              <HowItWorksPage />
            </>
          }
        />
        <Route
          path="/chatbot"
          element={
            <>
              <Helmet>
                <title>Chatbot - MDCAT.ai</title>
                <meta name="description" content="Interact with the MDCAT AI-powered chatbot for exam preparation." />
              </Helmet>
              <ProtectedRoute>
                <ChatbotInterface />
              </ProtectedRoute>
            </>
          }
        />
        
<Route
  path="/login"
  element={
    <>
      <Helmet>
        <title>Login - MDCAT.ai</title>
        <meta name="description" content="Log in to MDCAT.ai to access personalized exam preparation tools and resources." />
      </Helmet>
      <AuthPage type="login" />
    </>
  }
/>

<Route
  path="/signup"
  element={
    <>
      <Helmet>
        <title>Signup - MDCAT.ai</title>
        <meta name="description" content="Sign up for MDCAT.ai and start your journey to ace the MDCAT exam with AI-powered learning." />
      </Helmet>
      <AuthPage type="signup" />
    </>
  }
/>
        <Route
  path="/privacy-policy"
  element={
    <>
      <Helmet>
        <title>Privacy Policy - MDCAT.ai</title>
        <meta name="description" content="Learn about how MDCAT.ai handles user data and privacy." />
      </Helmet>
      <PrivacyPolicyPage />
    </>
 }
/>
        {/* Terms of Service Route */}
        <Route
          path="/terms-of-service"
          element={
            <>
              <Helmet>
                <title>Terms of Service - MDCAT.ai</title>
                <meta name="description" content="Learn about the terms and conditions for using MDCAT.ai." />
              </Helmet>
              <TermsOfServicePage />
            </>
          }
        />

      </Routes>
    </Router>
  );
}

export default App;
