import React from 'react';

const RenameModal = ({
  showRenameModal,
  setShowRenameModal,
  chatToRename,
  newChatName,
  setNewChatName,
  handleRenameChat,
}) => {
  if (!showRenameModal) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl p-6 w-11/12 max-w-md">
        <h3 className="text-lg font-semibold text-gray-800 mb-4">Rename Chat</h3>
        <input
          type="text"
          value={newChatName}
          onChange={(e) => setNewChatName(e.target.value)}
          placeholder="Enter new chat name"
          className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <div className="flex justify-end mt-6 space-x-3">
          <button
            onClick={() => setShowRenameModal(false)}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          <button
            onClick={handleRenameChat}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default RenameModal;
