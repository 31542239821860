import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import ChatWindow from './ChatWindow';
import RenameModal from './RenameModal';
import WelcomeScreen from './WelcomeScreen';
import { createNewChat } from '../../utils/chatAPI';
import config from '../../config';

// Config constants
const PY_URL = config.PY_URL;

const getAuthData = () => {
  const userStr = localStorage.getItem('user');
  if (userStr) {
    return { user: JSON.parse(userStr) };
  }
  return { user: null };
};

const client = axios.create({
  baseURL: config.NODE_URL,
});

client.interceptors.request.use(
  async (config) => {
    const { user } = await getAuthData();
    if (user) {
      config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const ChatbotInterface = () => {
  const [chats, setChats] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isWaitingForReply, setIsWaitingForReply] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chatName, setChatName] = useState("");
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [newChatName, setNewChatName] = useState("");
  const messagesEndRef = useRef(null);
  const [chatToRename, setChatToRename] = useState(null);
  const { user } = getAuthData();

  // Track window dimensions
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Update window size state on resize
  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const currentHeight = window.innerHeight;
      setWindowSize({
        width: currentWidth,
        height: currentHeight,
      });
      // If width is less than 627, force the sidebar to be closed.
      if (currentWidth < 627) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    // Call handler once to set initial state
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Condition: if the screen is less than or equal to 412x985, do not show the welcome screen.
  const isVerySmallScreen = windowSize.width <= 412 && windowSize.height <= 867;

  const fetchChats = async () => {
    if (!user) {
      setLoading(false);
      return;
    }

    try {
      const response = await client.get(`/chat/user/${user._id}`);
      if (response.data.success) {
        setChats(response.data.data);
      } else {
        setError(response.data.message || "Failed to fetch chats");
      }
    } catch (error) {
      setError("Failed to fetch chats");
      console.error("Error fetching chats:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchChats();
    }
  }, [user]);

  const fetchChatMessages = async (chatId) => {
    if (!chatId) return;

    setIsLoadingMessages(true);
    try {
      const response = await client.get(`/chat/${chatId}/messages`);
      if (response.data.success && response.data.data) {
        setChatName(response.data.data.chatName || "Untitled Chat");
        const messagesList = response.data.data.messages || [];
        const transformedMessages = messagesList.map(msg => ({
          id: msg._id,
          sender: msg.sender,
          content: msg.content,
          timestamp: msg.timestamp
        }));
        setMessages(transformedMessages);
      } else {
        setError("Failed to load chat messages");
      }
    } catch (error) {
      setError("Failed to load chat messages");
    } finally {
      setIsLoadingMessages(false);
    }
  };

  const handleOpenRenameModal = (chat) => {
    setChatToRename(chat._id);
    setNewChatName(chat.chatName);
    setShowRenameModal(true);
  };

  const handleRenameChat = async () => {
    if (!chatToRename || !newChatName.trim()) return;

    try {
      const response = await client.patch(`/chat/${chatToRename}/rename`, {
        chatName: newChatName,
      });

      if (response.data.success) {
        setChats(prevChats =>
          prevChats.map(chat =>
            chat._id === chatToRename
              ? { ...chat, chatName: newChatName }
              : chat
          )
        );
        if (activeChat && activeChat._id === chatToRename) {
          setChatName(newChatName);
        }
        setShowRenameModal(false);
        setChatToRename(null);
      }
    } catch (error) {
      setError("Failed to rename chat");
    }
  };

  const handleChatSelect = async (chat) => {
    setActiveChat(chat);
    setMessages([]);
    await fetchChatMessages(chat._id);
  };

  const handleSendMessage = useCallback(async () => {
    if (!newMessage.trim() || !activeChat || isWaitingForReply) return;

    setIsWaitingForReply(true);
    const messageContent = newMessage.trim();
    setNewMessage('');

    try {
      const response = await client.post('/chat/message', {
        chatId: activeChat._id,
        sender: 'user',
        content: messageContent,
      });

      const savedMessage = response.data.data;
      setMessages((prevMessages) => [...prevMessages, savedMessage]);

      const lastTenMessages = [...messages.slice(-9), savedMessage].map((msg) => ({
        sender: msg.sender,
        content: msg.content,
        timestamp: msg.timestamp,
      }));

      const aiResponse = await axios.post(`${PY_URL}/chat`, {
        chatId: activeChat._id,
        question: messageContent,
        chatHistory: lastTenMessages,
      });

      if (aiResponse.data.answer) {
        const botMessage = {
          sender: 'bot',
          content: aiResponse.data.answer,
          timestamp: new Date().toISOString(),
        };

        const botResponse = await client.post('/chat/message', {
          chatId: activeChat._id,
          sender: 'bot',
          content: botMessage.content,
        });

        setMessages((prevMessages) => [...prevMessages, botResponse.data.data]);
      }

      await fetchChatMessages(activeChat._id);
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message');
    } finally {
      setIsWaitingForReply(false);
    }
  }, [newMessage, activeChat, isWaitingForReply, messages]);

  const handleNewChat = async () => {
    try {
      const newChat = await createNewChat();
      setChats(prevChats => [newChat, ...prevChats]);
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  const handleDeleteChat = async (chatId) => {
    try {
      const response = await client.delete(`/chat/${chatId}`);
      if (response.data.success) {
        setChats(prev => prev.filter(chat => chat._id !== chatId));
        if (activeChat && activeChat._id === chatId) {
          setActiveChat(null);
          setMessages([]);
        }
      }
    } catch (error) {
      setError("Failed to delete chat");
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="flex h-screen overflow-hidden bg-gray-50 dark:bg-gray-900">
      {/* Sidebar */}
      <div
        className={`transition-all duration-300 ease-in-out 
          ${isSidebarOpen ? 'w-64' : 'w-16'} 
          bg-black shadow-md border-r`}
      >
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          chats={chats}
          loading={loading}
          activeChat={activeChat}
          handleChatSelect={handleChatSelect}
          handleNewChat={handleNewChat}
          handleOpenRenameModal={handleOpenRenameModal}
          handleDeleteChat={handleDeleteChat}
        />
      </div>

      {/* Main Chat Area */}
      <div className="flex-1 flex flex-col">
        {activeChat ? (
          <ChatWindow
            chatName={chatName}
            messages={messages}
            isLoadingMessages={isLoadingMessages}
            isWaitingForReply={isWaitingForReply}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            handleSendMessage={handleSendMessage}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        ) : (
          // If no active chat exists, render nothing when the screen is very small
          isVerySmallScreen ? (
            <div className="flex-1" />
          ) : (
            <div className="flex-1 flex items-center justify-center">
              <WelcomeScreen onNewChat={handleNewChat} setIsSidebarOpen={setIsSidebarOpen} />
            </div>
          )
        )}
      </div>

      <RenameModal
        showRenameModal={showRenameModal}
        setShowRenameModal={setShowRenameModal}
        chatToRename={chatToRename}
        newChatName={newChatName}
        setNewChatName={setNewChatName}
        handleRenameChat={handleRenameChat}
      />
    </div>
  );
};

export default ChatbotInterface;
