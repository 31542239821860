import React from 'react';
import { Helmet } from 'react-helmet';

function PrivacyPolicyPage() {
  return (
    <div className="p-8">
      <Helmet>
        <title>Privacy Policy - MDCAT.ai</title>
        <meta name="description" content="Learn about how MDCAT.ai handles user data and privacy." />
      </Helmet>
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>

      <p>Effective Date: 01/02/25</p>

      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">1. Information We Collect</h2>
        <p>
          We collect the following types of data when you use MDCAT.ai:
        </p>
        <ul className="list-disc ml-6">
          <li><strong>Notifications Access:</strong> We access notifications solely to keep you informed about your progress and app updates. No notification data is stored on our servers.</li>
          <li><strong>Microphone Access (Optional):</strong> If you choose to use the voice chat feature, we temporarily access your microphone to process your queries. Audio data is used only for real-time interaction and is not stored.</li>
          <li><strong>Cache Storage:</strong> We use cached data to improve app performance and reduce loading times.</li>
        </ul>
      </section>

      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">2. How We Use Your Information</h2>
        <p>
          The information collected is used solely to:
        </p>
        <ul className="list-disc ml-6">
          <li>Provide you with AI-powered learning experiences.</li>
          <li>Improve app performance and user experience.</li>
          <li>Process and respond to your voice-based interactions.</li>
        </ul>
      </section>

      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">3. Data Protection</h2>
        <p>
          We do not share your data with third parties. We use secure storage practices to safeguard cached data. Audio input is processed in real-time and discarded immediately.
        </p>
      </section>

      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">4. Children's Privacy</h2>
        <p>
          MDCAT.ai is not intended for children under the age of 13. We do not knowingly collect data from users in this age group.
        </p>
      </section>

      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">5. User Control</h2>
        <p>
          You can disable notification access and microphone permissions at any time in your device settings. Clearing the app cache will remove temporary data stored on your device.
        </p>
      </section>

      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">6. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at:
        </p>
        <p>Email: mdcatdotai@gmail.com</p>
        <p>Website: <a href="https://mdcat.ai.axthtech.com/privacy-policy" className="text-blue-600 underline">https://mdcat.ai.axthtech.com/privacy-policy</a></p>
      </section>

    </div>
  );
}

export default PrivacyPolicyPage;
