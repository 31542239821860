import React, { useRef, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Home, Send, Loader2 } from 'lucide-react';

// Utility function to clean bot messages
const cleanBotMessage = (message) =>
  message
    .replace(/(\w)\1{2,}/g, '$1') // Remove excessive repetitions
    .replace(/\s+/g, ' ')         // Replace multiple spaces with a single space
    .trim();

const ChatWindow = ({
  chatName,
  messages,
  isLoadingMessages,
  isWaitingForReply,
  newMessage,
  setNewMessage,
  handleSendMessage,
}) => {
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);

  // Scroll to bottom on new messages or typing indicator change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isTyping]);

  useEffect(() => {
    setIsTyping(isWaitingForReply);
  }, [isWaitingForReply]);

  const sendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      handleSendMessage();
    }
  };

  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <header className="flex items-center justify-between bg-gradient-to-r from-[#16c2d9] to-blue-500 dark:from-gray-800 dark:to-gray-600 shadow px-6 py-4 border-b border-gray-200 dark:border-gray-700">
        <div className="flex items-center space-x-4">
          <h2 className="text-xl font-bold text-white truncate">
            {chatName || 'Untitled Chat'}
          </h2>
        </div>
        <div className="flex items-center space-x-4">
          <a href="/" className="text-white hover:text-gray-200">
            <Home size={24} />
          </a>
        </div>
      </header>

      {/* Messages Container */}
      <main className="flex-1 overflow-y-auto p-6 bg-gray-100 dark:bg-gray-900">
        {isLoadingMessages && messages.length === 0 ? (
          <div className="flex justify-center items-center h-full">
            <Loader2 className="animate-spin text-gray-600 dark:text-gray-300" size={32} />
          </div>
        ) : (
          <>
            {messages.map((message, index) => (
  <div
    key={index}
    className={`fade-in flex mb-4 ${
      message.sender === 'user' ? 'justify-end' : 'justify-start'
    }`}
  >
    <div
      className={`max-w-[90%] p-2 rounded-lg shadow-sm break-all whitespace-normal
        ${
          message.sender === 'user'
            ? 'bg-[#2b2b2b] text-white'
            : 'bg-white text-gray-800 border border-gray-200 dark:bg-gray-800 dark:text-gray-100 dark:border-gray-700 '
        }
      `}
    >
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {message.sender === 'bot'
          ? cleanBotMessage(message.content)
          : message.content}
      </ReactMarkdown>
    </div>
  </div>
))}


            {/* Typing Indicator */}
            {isTyping && (
              <div className="fade-in flex justify-start mb-4">
                <div className="bg-white text-gray-800 p-4 rounded-lg shadow-sm border border-gray-200 dark:bg-gray-800 dark:text-gray-100 dark:border-gray-700 flex items-center space-x-2">
                  <Loader2 className="animate-spin text-gray-600 dark:text-gray-300" size={20} />
                  <span>Typing...</span>
                </div>
              </div>
            )}

            <div ref={messagesEndRef} />
          </>
        )}
      </main>

      {/* Input Area */}
      <footer className="border-t bg-white dark:bg-gray-800 p-4 border-gray-200 dark:border-gray-700">
        <form onSubmit={sendMessage} className="flex space-x-3">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 px-4 py-2 border rounded-full focus:outline-none focus:ring-1 focus:ring-black dark:focus:ring-white transition-all duration-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
          <button
            type="submit"
            disabled={!newMessage.trim() || isWaitingForReply}
            className="bg-[#16c2d9] hover:bg-[#0da6ba] text-white p-3 rounded-full disabled:opacity-50 transition-all duration-200"
          >
            <Send size={20} />
          </button>
        </form>
      </footer>
    </div>
  );
};

export default ChatWindow;
