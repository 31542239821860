import React from 'react';
import { MessageSquare, Save, Smartphone } from 'lucide-react';
import FloatingWhatsAppButton from '../FloatingWhatsAppButton.tsx';

const WelcomeScreen = ({ onNewChat, setIsSidebarOpen }) => {
  const getAuthData = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) {
      return { user: JSON.parse(userStr) };
    }
    return { user: null };
  };
  const { user } = getAuthData();

  return (
    <div className="relative w-full px-4 py-8 ">
      <div className="text-center space-y-6">
        <h1
          className="text-3xl lg:text-4xl font-extrabold text-gray-800 dark:text-white 
          px-6 py-4 bg-gradient-to-r from-[#16c2d9] to-blue-500 dark:from-gray-800 dark:to-gray-600 
          rounded-lg shadow-lg inline-block"
        >
          Welcome {user ? user.fullName : 'Student'}!
        </h1>
        <p className="text-xl lg:text-2xl text-gray-400 dark:text-gray-300">
          <span className="text-[#16c2d9] dark:text-blue-400 font-semibold">MDCAT.ai, </span>
          Your Trusted Personal Medical Study Assistant
        </p>
      </div>

      <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-6 max-w-6xl mx-auto">
        {/* Feature Card: Ask Questions */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6 hover:shadow-lg transition">
          <div className="flex flex-col items-center space-y-3">
            <MessageSquare size={36} className="text-[#16c2d9] dark:text-blue-400" />
            <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">Ask Questions</h2>
            <p className="text-gray-600 dark:text-gray-400 text-center">
              Get instant answers to your medical study questions
            </p>
          </div>
        </div>

        {/* Feature Card: Save Your Chats */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6 hover:shadow-lg transition">
          <div className="flex flex-col items-center space-y-3">
            <Save size={36} className="text-[#16c2d9] dark:text-blue-400" />
            <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">Save Your Chats</h2>
            <p className="text-gray-600 dark:text-gray-400 text-center">
              Organize discussions with custom names for easy reference
            </p>
          </div>
        </div>

        {/* Feature Card: Access Anywhere */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6 hover:shadow-lg transition">
          <div className="flex flex-col items-center space-y-3">
            <Smartphone size={36} className="text-[#16c2d9] dark:text-blue-400" />
            <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">Access Anywhere</h2>
            <p className="text-gray-600 dark:text-gray-400 text-center">
              Continue your studies on our mobile app with the same account
            </p>
          </div>
        </div>
      </div>

      <div className="mt-12 text-center space-y-4">
        <button
          onClick={onNewChat}
          className="bg-[#16c2d9] hover:bg-[#0da6ba] text-white px-8 py-4 rounded-lg transition flex items-center justify-center space-x-3 mx-auto text-lg"
        >
          <MessageSquare size={24} />
          <span>Start a New Chat</span>
        </button>
        <p className="text-gray-400 dark:text-gray-300">
          or select an existing chat from the list on the left
        </p>
      </div>
      <FloatingWhatsAppButton /> {/* Add the floating button */}

    </div>
  );
};

export default WelcomeScreen;
