import React from 'react';
import { Helmet } from 'react-helmet';

function TermsOfServicePage() {
  return (
    <div className="p-8">
      <Helmet>
        <title>Terms of Service - MDCAT.ai</title>
        <meta name="description" content="Learn about the terms and conditions for using MDCAT.ai." />
      </Helmet>
      <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
      <p>Effective Date: 01/02/25</p>
      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">1. Acceptance of Terms</h2>
        <p>
          By accessing or using MDCAT.ai, you confirm that:
        </p>
        <ul className="list-disc ml-6">
          <li>You are at least 13 years old.</li>
          <li>You have read, understood, and agree to abide by these Terms.</li>
          <li>You consent to the collection and use of your data as outlined in our <a href="/privacy-policy" className="text-blue-600 underline">Privacy Policy</a>.</li>
        </ul>
      </section>
      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">2. Use of the App</h2>
        <p>
          The App is designed to provide AI-powered learning experiences, including progress tracking, voice-based interactions, and performance optimization.
        </p>
        <ul className="list-disc ml-6">
          <li><strong>Eligibility:</strong> You must be at least 13 years old to use the App. If you are under 18, you must have parental consent.</li>
          <li><strong>Prohibited Activities:</strong> You agree not to:
            <ul className="list-disc ml-6">
              <li>Use the App for any illegal or unauthorized purpose.</li>
              <li>Attempt to reverse-engineer, modify, or distribute the App.</li>
              <li>Interfere with the App's functionality or violate any applicable laws.</li>
            </ul>
          </li>
        </ul>
      </section>
      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">3. User Responsibilities</h2>
        <p>
          As a user, you are responsible for:
        </p>
        <ul className="list-disc ml-6">
          <li>Managing your notification preferences via your device settings.</li>
          <li>Ensuring that your queries in the voice chat feature comply with our community guidelines.</li>
          <li>Clearing cached data from your device if you wish to remove temporary data.</li>
        </ul>
      </section>
      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">4. Intellectual Property</h2>
        <p>
          All content, features, and functionalities of the App, including but not limited to text, graphics, logos, algorithms, and software, are the property of MDCAT.ai or its licensors and are protected by intellectual property laws.
        </p>
        <p>
          You may not copy, reproduce, modify, or distribute any part of the App without prior written permission.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">5. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, MDCAT.ai shall not be liable for any direct, indirect, incidental, or consequential damages arising from your use of the App.
        </p>
        <p>
          Third-party links provided in the App are not endorsed by us, and we are not responsible for their content or practices.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">6. Termination</h2>
        <p>
          We reserve the right to suspend or terminate your access to the App at any time if you violate these Terms or engage in behavior that harms the App or its users.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">7. Changes to the Terms</h2>
        <p>
          We may update these Terms from time to time. Significant changes will be communicated via email or through the App. Continued use of the App after such changes constitutes acceptance of the updated Terms.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">8. Governing Law</h2>
        <p>
          These Terms are governed by the laws of the Islamic Republic of Pakistan. Any disputes arising from these Terms will be resolved in the courts of Pakistan.
        </p>
        <p>
          International users agree to comply with all applicable local laws and regulations in addition to these Terms.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-2xl font-semibold mb-2">9. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <p>Email: mdcatdotai@gmail.com</p>
        <p>Website: <a href="https://mdcat.ai.axthtech.com/terms-of-service" className="text-blue-600 underline">https://mdcat.ai.axthtech.com/terms</a></p>
      </section>
    </div>
  );
}

export default TermsOfServicePage;
